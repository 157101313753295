import { ConfigStateService } from '@abp/ng.core';
import {
  Component,
  OnInit,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerI18n, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomDatepickerI18n } from 'src/core/localizations/datetime-picker/datepicker-i18n';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { TimeZoneHelper } from 'src/core/services/helper/timezone-helper';

@Component({
  selector: 'ca-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatetimePickerComponent),
    },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class DatetimePickerComponent implements OnInit, ControlValueAccessor {
  _disable: boolean = false;
  _allowNull: boolean = false;
  isRTL: boolean;

  @ViewChild('dtp', { static: true, read: NgbDatepicker })
  dtp: NgbDatepicker;

  date: Date;
  @Input()
  minDate: Date;
  @Input()
  maxDate: Date;
  @Input()
  customClass: string;
  @Input()
  hideTime: boolean;
  @Output()
  changed: EventEmitter<{ id: number }> = new EventEmitter();

  get formatedMinDate(): NgbDateStruct {
    if (this.minDate) return this.getNgbStructDate(this.minDate);
    else return null;
  }
  get formatedMaxDate(): NgbDateStruct {
    if (this.maxDate) {
      return this.getNgbStructDate(this.maxDate);
    } else {
      return null;
    }
  }

  get formatedClass(): string {
    const tempClass = 'ca-dropdown-menu';
    if (this.customClass) return this.customClass;
    else return tempClass;
  }

  @Input()
  set disabled(value: boolean) {
    this._disable = value;
  }

  get disabled(): boolean {
    return this._disable;
  }

  @Input()
  set allowNull(value: boolean) {
    this._allowNull = value;
  }

  get allowNull(): boolean {
    return this._allowNull;
  }

  ngbDate: NgbDateStruct;
  ngbTime: NgbTimeStruct;
  dateTime: string;
  constructor(
    private parserFormatter: NgbDateParserFormatter,
    private caDatePie: CADatePipe,
    private timeZoneHelper: TimeZoneHelper,
    private config: ConfigStateService
  ) {}

  ngOnInit(): void {
    const localize = this.config.getOne('localization');
    this.isRTL = localize?.currentCulture.cultureName === 'ar';
  }

  ngAfterViewInit() {}

  writeValue(date: Date, setNull: boolean = false): void {
    if (date) {
      if (typeof date === 'string') {
        date = new Date(date);
      }
      this.date = date;
    } else {
      if (!this.allowNull) {
        this.date = new Date();
        this.date = this.timeZoneHelper.GetSystemTimeZoneConvertedDateInverse(this.date);
      }
    }

    if (typeof this.minDate === 'string') {
      this.minDate = new Date(this.minDate);
    }
    if (typeof this.maxDate === 'string') {
      this.maxDate = new Date(this.maxDate);
    }

    if (this.date) {
      if (this.maxDate && this.date > this.maxDate) {
        this.date.setTime(this.maxDate.getTime());
      }

      if (this.minDate && this.date < this.minDate) {
        this.date.setTime(this.minDate.getTime());
      }

      this.ngbDate = this.getNgbStructDate(this.date);
      this.ngbTime = this.getNgbTimeStruct(this.date);
      this.setDateTime();
      this.applyChanges();
    }
  }
  resetValue() {
    this.date = null;
    this.ngbDate = null;
    this.ngbTime = null;
    this.dateTime = '';
  }
  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }
  onChange: Function = (_: Date) => {};
  onTouched: Function = (_: Date) => {};
  onDateSelect(eventArgs) {
    this.ngbDate = eventArgs;
    this.checkDate();
    this.applyChanges();
    this.setDateTime();
  }

  onTimeChange(eventArgs) {
    setTimeout(() => {
      this.checkDate();
      this.applyChanges();
      this.setDateTime();
    }, 100);
  }
  checkDate() {
    let date = this.combineDateTime(this.ngbDate, this.ngbTime);
    if (this.minDate && date < this.minDate) {
      this.ngbTime = this.getNgbTimeStruct(this.minDate);
    } else if (this.maxDate && date > this.maxDate) {
      this.ngbTime = this.getNgbTimeStruct(this.maxDate);
    }
  }
  getNgbStructDate(date: Date): NgbDateStruct {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const ngbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    };
    return ngbDateStruct;
  }
  getNgbTimeStruct(date: Date): NgbTimeStruct {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const ngbTimeStruct = {
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    };
    return ngbTimeStruct;
  }
  combineDateTime(date: NgbDateStruct, time: NgbTimeStruct): Date {
    if (date && time) {
      const combinedDate = new Date(
        date.year,
        date.month - 1,
        date.day,
        time.hour,
        time.minute,
        time.second
      );
      return combinedDate;
    } else {
      const combinedDate = new Date(date.year, date.month - 1, date.day);
      return combinedDate;
    }
  }

  setDateTime() {
    this.dateTime = this.caDatePie.transform(
      this.combineDateTime(this.ngbDate, this.ngbTime),
      DateDisplayType.DateTimeWithoutSeconds
    );
    this.navigateToDate(this.date);
  }
  applyChanges(): void {
    this.date = this.combineDateTime(this.ngbDate, this.ngbTime);
    this.onChange(this.date);
    this.changed.emit();
  }
  getTimeStr(time: number): string {
    return time < 10 ? '0' + time.toString() : time.toString();
  }

  navigateToDate(date: Date): void {
    //TODO:Workaround clear model bug;
    this.dtp.navigateTo({ year: 1900, month: 1 });
    this.dtp.navigateTo(this.getNgbStructDate(date));
  }
}
