import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeatureConstants {
  static readonly QualityManagement = 'QualityManagementGroup.QualityManagement';
  static readonly SmartSampling = 'QualityManagementGroup.SmartSampling';
  static readonly UserFeedback = 'UserFeedbackGroup.UserFeedback';
  static readonly EvaluationDurationStopWatch =
    'QualityManagementGroup.EvaluationDurationStopWatch';
  static readonly Analytics = 'AnalyticsGroup.Analytics';
  static readonly ConversationSummarization = 'AnalyticsGroup.ConversationSummarization';
  static readonly FeatureNamedEntityRecognition = 'AnalyticsGroup.NamedEntityRecognition';
  static readonly LanguageDetection = 'AnalyticsGroup.LanguageDetection';
  static readonly AskGenAi = 'AnalyticsGroup.AskGenAi';
  static readonly IndexWithoutFullTextSearch = 'AnalyticsGroup.IndexWithoutFullTextSearch';
  static readonly DynamicReports = 'AnalyticsGroup.DynamicReports';
  static readonly NewIndexerInfrastructure = 'AnalyticsGroup.FeatureNewIndexerInfrastructure';
  static readonly CallRecorder = 'CallRecorderGroup.CallRecorder';
  static readonly GenesysIntegration = 'MarketPlaceIntegrationGroup.GenesysIntegration';
  static readonly GenesysIntegrationOutOfMarketplace =
    'MarketPlaceIntegrationGroup.GenesysIntegrationOutOfMarketplace';
  static readonly ZoomMeetingIntegration = 'MarketPlaceIntegrationGroup.ZoomMeetingIntegration';
  static readonly ZoomContactCenterIntegration =
    'MarketPlaceIntegrationGroup.ZoomContactCenterIntegration';
  static readonly AloTechIntegration = 'MarketPlaceIntegrationGroup.AloTechIntegration';
  static readonly AloTechV3Integration = 'MarketPlaceIntegrationGroup.AloTechV3Integration';
  static readonly PuzzelIntegration = 'MarketPlaceIntegrationGroup.PuzzelIntegration';
  static readonly ZendeskIntegration = 'MarketPlaceIntegrationGroup.ZendeskIntegration';
  static readonly AmazonIntegration = 'MarketPlaceIntegrationGroup.AmazonIntegration';
  static readonly AmazonIntegrationOutOfMarketplace =
    'MarketPlaceIntegrationGroup.AmazonIntegrationOutOfMarketplace';
  static readonly TeamsIntegration = 'MarketPlaceIntegrationGroup.TeamsIntegration';
  static readonly TwilioIntegration = 'MarketPlaceIntegrationGroup.TwilioIntegration';
  static readonly Five9Integration = 'MarketPlaceIntegrationGroup.Five9Integration';
  static readonly RealTime = 'RealTimeGroup.RealTime';
  static readonly Training = 'TrainingGroup.TrainingManagement';
  static readonly UserGroupManagement = 'UserGroupManagement.UserGroup';
  static readonly ScreenRecorder = 'ScreenRecorderGroup.ScreenRecorder';
  static readonly ExternalLogin = 'ExternalLoginGroup.ExternalLogin';
  static readonly CallType = 'FeatureCallTypeGroup.FeatureCallType';
  static readonly ChatType = 'FeatureChatTypeGroup.FeatureChatType';
  static readonly VideoCallType = 'FeatureVideoCallTypeGroup.FeatureVideoCallType';
  static readonly MeetingType = 'FeatureMeetingTypeGroup.FeatureMeetingType';
  static readonly EmailType = 'FeatureEmailTypeGroup.FeatureEmailType';
  static readonly SentimentAnalysis = 'SentimentAnalysisGroup.SentimentAnalysis';
  static readonly UserNamedLicensing = 'LicensingFeatureGroup.UserNamedLicensing';
  static readonly LdapLogin = 'Account.EnableLdapLogin';
  static readonly PremiumReporting = 'PremiumReportingGroup.PremiumReporting';
  static readonly GenerativeAI = 'GenerativeAIIntegrationGroup.GenerativeAIIntegration';
  static readonly GenerativeQA = 'RealTimeGroup.GenerativeQA';
  static readonly VirtualAgentIntegration = 'RealTimeGroup.VirtualAgentIntegration';
  static readonly VirtualTranslatorIntegration = 'FeatureVirtualTranslator';
  static readonly GenesysVirtualTranslatorIntegration = 'MarketPlaceIntegrationGroup.GenesysVirtualTranslatorIntegration';
  static readonly GenesysVirtualTranslatorIntegrationOutOfMarketplace = 'MarketPlaceIntegrationGroup.GenesysVirtualTranslatorIntegrationOutOfMarketplace';
}
